export const JobsData = [
  {
    jobTitle: "MERN Stack Developer",
    location: "Hyderabad, India",
    salary: "₹900,000 - ₹1,200,000 per year",
    jobDescription:
      "We are looking for a MERN Stack Developer to join our dynamic team. The successful candidate will work on developing and maintaining web applications using MongoDB, Express.js, React, and Node.js.",
    requiredSkills: [
      "MongoDB",
      "Express.js",
      "React",
      "Node.js",
      "JavaScript",
      "HTML",
      "CSS",
    ],
    employmentType: "Full-time",
    experienceLevel: "Mid-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@techinnovations.com",
  },
  {
    jobTitle: "AWS Cloud Engineer",
    location: "Hyderabad, India",
    salary: "₹1,000,000 - ₹1,500,000 per year",
    jobDescription:
      "Seeking an AWS Cloud Engineer to manage and enhance our cloud infrastructure. The candidate should have strong experience in AWS services and cloud architecture.",
    requiredSkills: [
      "AWS",
      "Cloud Architecture",
      "DevOps",
      "Python",
      "Linux",
      "Terraform",
    ],
    employmentType: "Full-time",
    experienceLevel: "Mid-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@cloudservices.com",
  },
  {
    jobTitle: "DevOps Engineer",
    location: "Hyderabad, India",
    salary: "₹1,200,000 - ₹1,800,000 per year",
    jobDescription:
      "We need a DevOps Engineer to improve our CI/CD pipelines and automate our deployment processes. Experience with Kubernetes and Docker is essential.",
    requiredSkills: [
      "DevOps",
      "CI/CD",
      "Kubernetes",
      "Docker",
      "Jenkins",
      "Ansible",
    ],
    employmentType: "Full-time",
    experienceLevel: "Mid-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@infrastructure.io",
  },
  {
    jobTitle: "Senior MERN Stack Developer",
    location: "Hyderabad, India",
    salary: "₹1,500,000 - ₹2,000,000 per year",
    jobDescription:
      "Looking for a Senior MERN Stack Developer with extensive experience in building and scaling web applications. Leadership and project management skills are a plus.",
    requiredSkills: [
      "MongoDB",
      "Express.js",
      "React",
      "Node.js",
      "JavaScript",
      "HTML",
      "CSS",
      "Project Management",
    ],
    employmentType: "Full-time",
    experienceLevel: "Senior-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@techinnovations.com",
  },
  {
    jobTitle: "AWS Solutions Architect",
    location: "Hyderabad, India",
    salary: "₹2,000,000 - ₹2,500,000 per year",
    jobDescription:
      "We are hiring an AWS Solutions Architect to design and implement scalable cloud solutions. Strong understanding of AWS services and best practices is required.",
    requiredSkills: [
      "AWS",
      "Cloud Architecture",
      "Networking",
      "Security",
      "DevOps",
    ],
    employmentType: "Full-time",
    experienceLevel: "Senior-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@cloudservices.com",
  },
  {
    jobTitle: "Junior DevOps Engineer",
    location: "Hyderabad, India",
    salary: "₹600,000 - ₹900,000 per year",
    jobDescription:
      "An entry-level position for a Junior DevOps Engineer. The role involves assisting in the development and maintenance of our CI/CD pipelines.",
    requiredSkills: ["DevOps", "CI/CD", "Linux", "Shell Scripting", "Jenkins"],
    employmentType: "Full-time",
    experienceLevel: "Entry-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@infrastructure.io",
  },
  {
    jobTitle: "Full Stack Developer (MERN)",
    location: "Hyderabad, India",
    salary: "₹1,000,000 - ₹1,400,000 per year",
    jobDescription:
      "Hiring a Full Stack Developer with expertise in the MERN stack to work on various web development projects.",
    requiredSkills: [
      "MongoDB",
      "Express.js",
      "React",
      "Node.js",
      "JavaScript",
      "HTML",
      "CSS",
    ],
    employmentType: "Full-time",
    experienceLevel: "Mid-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@techinnovations.com",
  },
  {
    jobTitle: "AWS DevOps Specialist",
    location: "Hyderabad, India",
    salary: "₹1,800,000 - ₹2,200,000 per year",
    jobDescription:
      "We are looking for an AWS DevOps Specialist to manage our cloud infrastructure and automate deployment processes.",
    requiredSkills: [
      "AWS",
      "DevOps",
      "Terraform",
      "Python",
      "Docker",
      "Kubernetes",
    ],
    employmentType: "Full-time",
    experienceLevel: "Senior-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@cloudservices.com",
  },
  {
    jobTitle: "Lead DevOps Engineer",
    location: "Hyderabad, India",
    salary: "₹2,500,000 - ₹3,000,000 per year",
    jobDescription:
      "Seeking a Lead DevOps Engineer to lead our DevOps team and drive the development of our CI/CD infrastructure.",
    requiredSkills: [
      "DevOps",
      "CI/CD",
      "Kubernetes",
      "Docker",
      "Jenkins",
      "Leadership",
    ],
    employmentType: "Full-time",
    experienceLevel: "Senior-level",
    benefits: [
      "Health insurance",
      "Provident Fund",
      "Paid time off",
      "Flexible schedule",
    ],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@infrastructure.io",
  },
  {
    jobTitle: "MERN Stack Developer Intern",
    location: "Hyderabad, India",
    salary: "₹300,000 - ₹500,000 per year",
    jobDescription:
      "An internship opportunity for a MERN Stack Developer. The role involves learning and assisting in the development of web applications using the MERN stack.",
    requiredSkills: [
      "MongoDB",
      "Express.js",
      "React",
      "Node.js",
      "JavaScript",
      "HTML",
      "CSS",
    ],
    employmentType: "Internship",
    experienceLevel: "Entry-level",
    benefits: ["Health insurance", "Paid time off", "Flexible schedule"],
    postedDate: "2024-06-28",
    applicationDeadline: "2024-07-28",
    contactEmail: "hr@techinnovations.com",
  },
];
